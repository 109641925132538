h4{
    margin-top: 5rem;
    font-weight: 600;
    font-weight: 900;
    /* text-shadow: 0 0 3px rgb(0, 107, 14); */
    color: #62fdc2;
    /* background: linear-gradient(to right, #00fa70, #00fa70, #00fa9a, #00fafa, #00fa85); */
    background-size: 400%;
    /* -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    animation: animate 10s linear infinite; */
}

option{
    text-transform: uppercase;
}

.img2{
   
    width: 70%;
    position: relative;
    left: 00%;
    margin: 5rem auto -5rem auto
}

.inputs{
    margin: 1.5rem;
    padding: 1rem;
    width: 70%;
}
@media screen and (max-width: 768px){
    .inputs{
        width: 80%;
        justify-content: center;
    }
    .img2{
        margin-left: 50px;
    }

    
}