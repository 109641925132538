@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,700;1,200;1,400&display=swap');
.navbar-brand{
    color: #fff;
    font-weight: 700;
    font-size: 2rem;
    font-family: 'Poppins', sans-serif;
}

.navbar-brand:hover{
    color: #fff;
}

#hamburger{
    background: #353535;
    color: #fff;
    width: 60px;
    height: 50px;
}

#hamburger:hover{
    background: #515151;
}
.nav-link{
    margin: 0 10px
}

.nav-link, #h{
    /* color: rgba(255, 255, 255, 0.664); */
    color: #fff;
    font-size: 1.2rem;
    text-decoration: none !important;
    border: none;
}

a{
    text-decoration: none;
}

.nav-link:hover, #h:hover{
    color: #6633CC;
}

#nav{
    background-color: transparent;
    backdrop-filter: blur(10px);
}
.collapse{
    margin-left: 820px;
}
#logo-img{
    margin-left: 60px;
}
@media screen and (max-width: 900px){
    .collapse{
        margin-left: 0px;
    }
    #logo-img{
        margin-left: 0px;
    }
    .navbar-brand{
        font-size: 1.5rem;
    }
}
    
