
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;700&display=swap');
.container{
    margin-top: 5rem;
}

.img{
    /* background-color: #fff; */
    width: 100%;
    width: 50%;
    position: relative;
    top: 25%;
    left: 15%;
    margin-bottom: 3rem;
    
}

.foot{
    color: rgba(255, 255, 255, 0.61);
    background-color: #141E27;
    padding: 1rem;
    text-align: center;
    box-shadow: 0 .3rem .8rem rgba(255, 255, 255, 0.267) inset;
}

.text-content{
    font-weight: 700;
    padding: 1.5rem;
    height: 45%; 
}
.text-content{
    margin-top: 50px;
}
.text-content > h1, h3{
   
    font-family: Poppins;
    font-weight: 900;
    text-shadow: 0 0 3px #0000;
    background-size: 400%;
    color: #62fdc2;
    /* background: linear-gradient(to right, #00fa70, #00fa70, #00fa9a, #00fafa, #00fa85);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    animation: animate 10s linear infinite; */
}

@keyframes animate{
    0%{
        background-position: 0%;
    }
    100%{
        background-position: 350%;
    }
}

.text-content > p{
    margin-top: 3rem;
    line-height: 2;
    color: rgba(255, 255, 255, 0.61);
    font-size: 1.2rem;
}